import {Store} from "./store";

export class FavoriteJokeStore extends Store {
  constructor() {
    super('dad-jokes-favorites');
    this._jokes = this.readStorage();
  }

  get(filter, page = 1) {
    let jokes = Object.values(this._jokes).sort((a, b) => b.added - a.added);

    const start = (page - 1) * 10;
    const end = start + 10;
    const pagedJokes = jokes.slice(start, end);

    return {
      results: pagedJokes,
      count: jokes.length,
      hasNextPage: end < jokes.length,
      nextPage: end < jokes.length ? ++page : null,
    };
  }

  favoriteIds() {
    return Object.keys(this._jokes);
  }

  isFavorite(joke) {
    return this.favoriteIds().includes(joke.id);
  }

  toggle(joke) {
    if (this.isFavorite(joke)) {
      delete this._jokes[joke.id];
    } else {
      this._jokes[joke.id] = {...joke, added: Date.now()};
    }
    this.save(this._jokes);
  }
}
