export const storage = {
  getItem: (key) => {
    try {
      const value = window.localStorage.getItem(key);
      return value === null ? null : JSON.parse(value);
    } catch (error) {
      console.error(error);
    }
  },
  setItem: (key, value) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  },
  clearItem: (key) => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  }
}
