import {Page} from "../components";
import {getLocationState, debounce} from "../utils";

export class FavoritesPage extends Page {

  constructor() {
    super('favorites');
    this.setTitle('Favorite Jokes | Dad Jokes');

    this._bind();
    // const {filter} = getLocationState();
    this.render();
  }

  _bind() {
    this.dadJokes.JokeStore.addEventListener('save', () => this.handleListChange());
    this.$.loadMoreBtn.onclick = debounce(() => this.render());
  }

  handleListChange() {
    this.List.$.root.childNodes.forEach($jokeItem => {
      if (!$jokeItem.hasAttribute('isFav')) {
        setTimeout(() => {
          $jokeItem.remove();
          this.setCount(this.List.count());
        }, 1000);
      }
    })
  }

  render() {
    const loadMoreNextPage = this.$.loadMoreBtn.dataset.nextPage;
    const data = this.dadJokes.favorites('', loadMoreNextPage);
    const {jokes, count, showLoadMore, nextPage} = data;
    this.setCount(count);
    this.appendJokes(jokes);
    this.showLoadMoreBtn(showLoadMore);
    this.setLoadMorePage(nextPage);
  }

}
