import {JokeList} from "./List.js";
import {DadJokes} from "../services";

export class Page {
  constructor(name) {

    this.$ = {
      heading: document.querySelector('[data-joke="heading"]'),
      count: document.querySelector('[data-joke="count"]'),
      loadMoreBtn: document.querySelector('[data-joke="load-more"]'),
      list: document.querySelector('[data-joke="list"]'),
    }

    this._hideAll();
    this._show(name);
    this._setHeading(name);
    this.showLoadMoreBtn(false);
    this.setLoadMorePage();

    this.dadJokes = new DadJokes();
    this.List = new JokeList(this.$.list);
    this.List.$.root.addEventListener('click', (e) => this.jokeItemClickHandler(e));
  }

  jokeItemClickHandler(e) {
    const handleAction = (action, joke) => {
      const actions = {
        share: () => {
          console.log('share', joke);
          if (!navigator.share) {
            alert('can not share from this device');
            return;
          }
          navigator.share({
            title: 'Dad Jokes | MH',
            text: `${joke.text} [${joke.category}]`,
            url: 'dadjokes.matthewhopps.com',
          })
        },
        favorite: () => {
          console.log('favorite', joke);
          let svgPath = $jokeItem.querySelector('.favorite-icon path');
          if (joke.isFav) {
            $jokeItem.removeAttribute('isFav');
            svgPath.classList.remove('is-fav');
          } else {
            $jokeItem.setAttribute('isFav', '');
            svgPath.classList.add('is-fav');
          }
          this.dadJokes.toggleFavorite(joke);
        },
        read: () => {
          console.log('play', joke);
          const utter = new SpeechSynthesisUtterance(joke.text);
          speechSynthesis.speak(utter);
          let svgPath = $jokeActionBtn.querySelector('path');
          svgPath.classList.add('is-playing');
          setTimeout(() => svgPath.classList.remove('is-playing'), 1000);
        },
      }
      actions[action]();
    }

    const $jokeItem = e.target.closest('.joke-item');
    if (!$jokeItem) return;
    const $jokeActionBtn = e.target.closest('button');
    if (!$jokeActionBtn) return;

    const action = $jokeActionBtn.dataset.jokeAction;
    const joke = {
      id: $jokeItem.dataset.id,
      isFav: $jokeItem.hasAttribute('isFav'),
      category: $jokeItem.querySelector('[data-joke="category"]')?.textContent,
      text: $jokeItem.querySelector('[data-joke="text"]')?.textContent,
    }

    handleAction(action, joke);
  }

  _hideAll() {
    // hide all page related components - wrapped in an element with data-page="..." attribute
    document.querySelectorAll('[data-page]').forEach((page) => {
      page.classList.add('hidden');
    });
  }

  _show(pageName) {
    document.querySelectorAll(`[data-page="${pageName}"]`).forEach(($pageElement) => {
      $pageElement.classList.remove('hidden');
    });
  }

  _setHeading(text) {
    this.$.heading.textContent = text;
  }

  setTitle(pageTitle) {
    document.title = pageTitle;
  }

  resetList() {
    this.List.clear();
    this.setCount();
    this.showLoadMoreBtn(false);
    this.setLoadMorePage();
  }

  setCount(count) {
    this.$.count.textContent = count ? `${count}` : '0';
  }

  appendJokes(jokes) {
    this.List.append(jokes);
  }

  prependJokes(jokes) {
    this.List.prepend(jokes);
  }

  showLoadMoreBtn(show) {
    if (show) {
      this.$.loadMoreBtn.classList.remove('hidden')
    } else {
      this.$.loadMoreBtn.classList.add('hidden');
    }
  }

  setLoadMorePage(pageNum) {
    this.$.loadMoreBtn.dataset.nextPage = pageNum ? pageNum : 1;
  }

  // render(name) {
  //   console.log('Page rendering');
  // }
}
