const devPort = '1234';
const devPort2 = '1338'
const isLocalHost = [
  `localhost:${devPort}`,
  `0.0.0.0:${devPort}`,
  `10.0.1.111:${devPort}`,
  `localhost:${devPort2}`,
  `0.0.0.0:${devPort2}`,
  `10.0.1.111:${devPort2}`,
].includes(window.location.host);
const BASE_URL = (isLocalHost) ? 'http://10.0.1.111:8008/api/joke' : 'https://django.matthewhopps.com/api/joke';
console.log('window.location.host: ', window.location.host);
console.log('BASE_API_URL: ', BASE_URL);

// TODO: if running on VM server with docker can i use localhost address for api access?

export class JokeApi {

  constructor() {
    this.BASE_URL = BASE_URL;
  }

  async get(path) {
    try {
      const response = await fetch(`${this.BASE_URL}${path}`);
      if (response.ok) {
        return await response.json();
      } else {
        if (response.status === 404) throw new Error('404 - Not Found');
        if (response.status === 500) throw new Error('500 - Internal Error');
        throw new Error(response.status);
      }
    } catch (err) {
      console.log('api.js: err: ', err);
      // return null;
      throw err; // forward error
    }
  }

  random(category) {
    // adult=true -> show all
    // adult=only -> show only safe=False
    // if ('adult' in localStorage)

    let url = `/random`;
    if (category && category !== 'ANY') {
      url += `?category=${category}`
    }
    return this.get(url);
  }

  search(query, page = 1) {
    return this.get(`/?search=${query}&page=${page}`);
  }
}
