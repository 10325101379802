import {HomePage, SearchPage, FavoritesPage} from "./pages";
import {getLocationState} from "./utils";
import {ThemeStore} from "./services/themeStore";

export class App {
  constructor() {
    this.$ = {
      menuBtn: document.querySelector('[data-menu="btn"]'),
      menu: document.querySelector('[data-menu="menu"]'),
      toggleMenu: () => {
        this.$.menu.classList.toggle('show');
        this.$.menuBtn.classList.toggle('opened');
      },
    };
    this.ThemeStore = new ThemeStore();
    this._bind();
  }

  _bind() {
    this.$.menuBtn.addEventListener('click', () => this.$.toggleMenu());

    this.$.menu.addEventListener('click', (e) => {
      console.log('e.target: ', e.target);
      let button = e.target.closest('.nav-btn');

      const color = button?.dataset.color;
      if (color) this.ThemeStore.setPrimaryColor(color);

      // close nav menu
      if (e.target.classList.contains('backdrop') || button?.classList.contains('nav-btn')) {
        this.$.toggleMenu();
      }
    });
  }

  render() {
    const pages = [
      {path: '', component: HomePage},
      {path: 'random', component: HomePage},
      {path: 'search', component: SearchPage},
      {path: 'favorites', component: FavoritesPage},
      // {path: 'safe', component: SafeJokesPage},
    ];

    const {pageName} = getLocationState();
    const Page = pages.find((page) => page.path === pageName);
    console.log('Page: ', Page);
    Page ? new Page.component() : window.location = '/';
  }
}
