import {JokeApi} from "./api";
import {FavoriteJokeStore} from "./favoriteJokeStore";

export class DadJokes {

  constructor() {
    this.JokeStore = new FavoriteJokeStore();
    this.JokeAPI = new JokeApi();
  }

  async random(filter) {
    const joke = await this.JokeAPI.random(filter);
    return this.setFavoriteProp(joke);
  }

  async search(query, page) {
    const data = await this.JokeAPI.search(query, page);
    const {results, count, next_page} = data;
    return {
      jokes: this.setFavoriteProp(results),
      count,
      showLoadMore: next_page !== null,
      nextPage: next_page,
    };
  }

  setFavoriteProp(jokes) {
    const favIds = this.JokeStore.favoriteIds();
    const jokeList = Array.isArray(jokes) ? jokes : [jokes];
    return jokeList.map(joke => {
      return {...joke, isFav: favIds.includes(joke.id)};
    });
  }

  toggleFavorite(joke) {
    this.JokeStore.toggle(joke);
  }

  favorites(filter, page) {
    const data = this.JokeStore.get(filter, page);
    const {results, count, hasNextPage, nextPage} = data;
    return {
      jokes: this.setFavoriteProp(results),
      count,
      showLoadMore: hasNextPage,
      nextPage,
    };
  }

}
