import {storage} from "./storage";

export class Store extends EventTarget {
  constructor(localStorageKey) {
    super();
    this._localStorageKey = localStorageKey;
    // handle changes in another window
    window.addEventListener("storage", () => {
        this.readStorage();
        this.save();
      },
      false
    );
  }

  readStorage() {
    return storage.getItem(this._localStorageKey) ?? {};
  }

  save(value) {
    storage.setItem(this._localStorageKey, value);
    console.log('this.dispatchEvent(new CustomEvent("save"));');
    this.dispatchEvent(new CustomEvent("save"));
  }

  remove() {
    storage.clearItem(this._localStorageKey);
  }

}
