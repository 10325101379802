import {Store} from "./store";

export class ThemeStore extends Store {
  constructor() {
    super('dad-jokes-theme-color');
    this._COLORS = ['green', 'blue', 'purple', 'orange'];
    this._themeColor = this.readStorage();
    this.setPrimaryColor(this._themeColor);
  }

  setPrimaryColor(color) {
    this._setPrimaryColorClass(color);
    this._themeColor = color;
    this.save(this._themeColor);
  }

  _setPrimaryColorClass(color) {
    // clear color names from html class list
    for (const color of this._COLORS) {
      document.documentElement.classList.remove(color);
    }
    // add selected theme color to html class list
    if (this._COLORS.includes(color)) {
      document.documentElement.classList.add(color);
    } else {
      document.documentElement.classList.add('green');
    }
  }

}
