import {Page} from "../components";
import {debounce} from "../utils";

export class SearchPage extends Page {

  constructor() {
    super('search');
    this.setTitle('Search Jokes | Dad Jokes');

    this.$ = {
      ...this.$,  // keep parent selectors
      form: document.querySelector('[data-search="form"]'),
      input: document.querySelector('[data-search="input"]'),
      clearBtn: document.querySelector('[data-search="clear-btn"]'),
      setInputValue: (value) => this.$.input.value = value,
      resetForm: () => {
        this.$.form.reset();
        this.$.input.focus();
        this.query = '';
      },
    }

    this.$.resetForm();
    this.$.setInputValue(this.query);
    this._bind();
    this.render();
  }

  _bind() {
    // load more jokes
    this.$.loadMoreBtn.onclick = debounce(() => this.render());
    // search
    this.$.input.addEventListener('input', debounce((e) => {
      const query = new FormData(this.$.form).get('query');
      if (query !== this.query) {
        this.resetList();
        this.query = query;
      }
      this.render();
    }, 350));
    // clear search
    this.$.clearBtn.addEventListener('click', () => {
      this.$.resetForm();
      this.resetList();
    });
    // prevent default form submit
    this.$.form.addEventListener('submit', e => {
      e.preventDefault();
      console.log('prevent default submit');
    });
  }

  render() {
    if (!this.query) return;
    const loadMoreNextPage = this.$.loadMoreBtn.dataset.nextPage;
    this.dadJokes.search(this.query, loadMoreNextPage).then((data) => {
      const {jokes, count, showLoadMore, nextPage} = data;
      this.setCount(count);
      this.appendJokes(jokes);
      this.showLoadMoreBtn(showLoadMore);
      this.setLoadMorePage(nextPage);
    }).catch(err => {
      this.setCount('X');
      this.List.renderError(err);
    });
  }

}
