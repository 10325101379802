export const getLocationState = () => {

  const {pathname, hash, search} = document.location;

  return {
    pageName: pathname ? pathname.split('/')[1] : '',
    filter: hash ? hash.split('#')[1] : '',
    // query: search ? new URLSearchParams(search).get('query') : '',
  };
};
