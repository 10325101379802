import {Page} from "../components";
import {getLocationState, debounce} from "../utils";

export class HomePage extends Page {

  constructor() {
    super('random');
    this.setTitle('Random Jokes | Dad Jokes');

    this.$ = {
      ...this.$,  // keep parent selectors
      getJokeBtn: document.querySelector('[data-random="btn"]'),
      filterSelect: document.querySelector('[data-random="filter"]'),
      setFilterSelect: (filter = 'ANY') => {
        const filterOptions = [...this.$.filterSelect.options].map((option) => option.value);
        this.$.filterSelect.value = filterOptions.includes(filter) ? filter : 'ANY'
      },
      loadingBtn: {
        originalText: '',
        off: () => {
          this.$.getJokeBtn.textContent = this.$.loadingBtn.originalText;
          this.$.getJokeBtn.disabled = false;
        },
        on: () => {
          this.$.loadingBtn.originalText = this.$.getJokeBtn.textContent;
          this.$.getJokeBtn.disabled = true;
          this.$.getJokeBtn.textContent = '• • •';
        }
      },
    }

    const {filter} = getLocationState();
    this.$.setFilterSelect(filter);
    this._bind();
    this.render();
  }

  _bind() {
    this.$.getJokeBtn.addEventListener('click', debounce(() => this.render()));
    this.$.filterSelect.addEventListener('change', (e) => {
      location.hash = e.target.value;  // changes hash (calls the hashchange listener)
    });
    window.addEventListener('hashchange', (e) => {
      const {filter} = getLocationState();
      this.$.setFilterSelect(filter);
      this.resetList();
      this.render();
    });
  }

  render() {
    this.$.loadingBtn.on();
    this.dadJokes.random(this.$.filterSelect.value).then(joke => {
      this.setCount(this.List.count() + 1);
      this.prependJokes(joke);
    }).catch(err => {
      this.setCount('X');
      this.List.renderError(err);
    }).finally(() => {
      this.$.loadingBtn.off();
    });
  }

}
