export class JokeList {
  constructor($root) {
    this.$ = {
      root: $root,
      jokeItemTemplate: document.getElementById('joke-item-template'),
      cloneJokeItemTemplate: () => this.$.jokeItemTemplate.content.cloneNode(true),
    }
    this.clear();
  }

  clear() {
    this.$.root.replaceChildren();
  }

  count() {
    return this.$.root.children.length;
  }

  createJokeItem(joke) {
    const $jokeItemTemp = this.$.cloneJokeItemTemplate();
    let $jokeItem = $jokeItemTemp.querySelector('[data-joke="item"]');

    const $ji = {
      setId: (id) => $jokeItem.dataset.id = id,
      setText: (text) => $jokeItem.querySelector('[data-joke="text"]').textContent = text,
      setCategory: (text) => $jokeItem.querySelector('[data-joke="category"]').textContent = text,
      setFavorite: (isFav) => {
        if (isFav) {
          $jokeItem.setAttribute('isFav', '');
          $jokeItem.querySelector('.favorite-icon path').classList.add('is-fav');
        } else {
          $jokeItem.removeAttribute('isFav');
        }
      }
    }

    $ji.setId(joke.id);
    $ji.setText(joke.text);
    $ji.setCategory(joke.category);
    $ji.setFavorite(joke.isFav);
    return $jokeItem;
  }

  createJokes(jokes) {
    let $fragment = document.createDocumentFragment();
    $fragment.append(...jokes.map((joke) => this.createJokeItem(joke)));
    return $fragment;
  }

  prepend(jokes) {
    this.$.root.prepend(this.createJokes(jokes));
  }

  append(jokes) {
    this.$.root.append(this.createJokes(jokes));
  }

  render(jokes) {
    if (jokes) this.$.root.replaceChildren(...jokes.map((joke) => this.createJokeItem(joke)));
    // TODO: ? h1.textContent = 'No Jokes Found';
    else {
      const errorElement = document.createElement('h1');
      errorElement.textContent = 'Error - Please Refresh and try again';
      this.$.root.replaceChildren(errorElement);
    }
  }

  renderError(err) {
    const errorElement = document.createElement('h1');
    errorElement.textContent = 'Error: Please Refresh and try again';
    this.$.root.replaceChildren(errorElement);
  }

}
